<!-- 通用列表 -->
<template>
  <div class="lists centebox">
    <span>
      <div class="heasd">
        <i class="el-icon-arrow-left" @click="back" style="margin: 0 8px"></i>
        最新政策
      </div>
    </span>
    <ul>
      <li v-for="(item, k) in newzclsit" :key="k" v-show="item.status == 1">
        <span>{{ item.createTime | formatDate }}</span>
        <router-link :to="'/detail/' + item.id">{{ item.title }}</router-link>
      </li>
    </ul>
    <div class="hr-10"></div>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="pageSize"
      @current-change="requestPage"
    />

    <div class="hr-10"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
      pages: 0,
      pageSize: 10,
      total: 1,
      routelist: [],
      columnId: "",
      Columnname: "",
      newzclsit: "",
    };
  },
  created() {
    this.routelist = this.$route.matched;
    this.columnId = this.$route.query.id || "";
    this.Getlumnlist(this.columnId);
    this.getColumn(this.columnId);
  },
  //用于存放所有的事件方法集合
  methods: {
    back() {
      this.$router.back();
    },
    Getlumnlist(column) {
      const self = this;
      this.$axios
        .get("/column/page")
        .then((response) => {
          if (response.data.status === 200) {
            response.data.data.records.forEach((array) => {
              if (array.name === "最新政策") {
                this.columnId = array.id;
                this.$axios
                  .get("/article/column", {
                    params: {
                      columnId: array.id,
                      current: 1,
                      size: this.pageSize,
                    },
                  })
                  .then((response) => {
                    this.newzclsit = response.data.data.records;
                    this.total = response.data.data.total;
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              }
            });
          } else {
            this.$root.warn("数据加载失败");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getColumn(Id) {
      const self = this;
      this.$axios
        .get("/column", {
          params: {
            id: Id,
          },
        })
        .then(function(res) {
          self.Columnname = res.data.data.name;
        });
    },

    //  分页
    requestPage(value) {
      this.page = value;
      let self = this;
      self.$axios
        .get("/article/column", {
          params: {
            columnId: self.columnId,
            current: value,
            size: self.pageSize,
          },
        })
        .then(function(response) {
          self.newzclsit = response.data.data.records;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.heasd {
  height: 44px;
  border-bottom: 1px #ddd solid;
  text-align: left;
  line-height: 40px;
  padding: 6px 0 0 16px;
  color: #2380d7;
  font-weight: bold;
}
.lists {
  background: #fff;
  width: 1200px;

  ul {
    padding: 0 20px;
    min-height: 500px;
    li {
      height: 48px;
      background: url("../../assets/diann.jpg") no-repeat left center;

      a {
        font-size: 16px;
        text-decoration: none;
        color: #333;
        line-height: 40px;
        display: block;
        float: left;
        padding: 6px 0 0 24px;
      }

      a:hover {
        color: #c00;
      }

      span {
        font-size: 16px;
        color: #888;
        float: right;
        line-height: 40px;
        display: block;
      }
    }
  }
}
@import "../../styles/common_responsive.less";
</style>
